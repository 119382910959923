<template>
  <b-card title="Lista Interazioni">
    <b-row>
      <b-col class="d-flex justify-content-between mb-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-filter-col-tbl
          variant="primary"
          class="btn-icon"
        >
          <feather-icon icon="SettingsIcon" />
        </b-button>
        <FilterListModal
          :columns="columns.slice(0, columns.length - 1)"
          @filterTable="filterColumns"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle.sidebar-add-new-engagment
          variant="outline-primary"
        >
          Aggiungi
        </b-button>
      </b-col>
    </b-row>
    <!-- input filter -->
    <div class="">
      <b-row class="d-flex">
        <b-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class=""
        >
          <b-form-group
            class=""
          >
            <h5>Data Inizio</h5>
            <b-form-datepicker
              id="datepickerStart"
              v-model="dateStart"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="it"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <b-form-group
            class=""
          >
            <h5>Data Fine</h5>
            <b-form-datepicker
              id="datepickerFinish"
              v-model="dateEnd"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="1"
          class="mt-md-250"
        >
          <b-button
            @click="resetDate"
          >
            Reset
          </b-button>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="4"
          lg="5"
          class="d-flex justify-content-end"
        >
          <b-form-group>
            <div>
              <h5 class="mr-1">
                Cerca
              </h5>
              <b-form-input
                v-model="searchTerm"
                placeholder="Ricerca generale"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columnsFiltered"
      :rows="filterType"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-row-click="showDetails"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Dettagli</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-toggle.sidebar-add-new-engagment
                @click="prepareSidebarToEdit(props.row, 'Modifica Interazione')"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','30']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <div>
      <engagment-event-handler
        ref="sidebar-engagment"
      />
      <!-- <engagment-event-handler
        ref="sidebar-engagment"
        @remove-event="removeCollaborator"
        @edit-collaborators="editCollaborator"
        @add-collaborator="addCollaborator"
      /> -->
    </div>
    <!--    <template #code>
      {{ codeColumnSearch }}
    </template> -->
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BCol, BRow, BFormDatepicker, VBToggle,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import FilterListModal from '@core/components/tableComponents/modals/FilterListModal.vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import store from '@/store/index'
import EngagmentEventHandler from './engagmentEventHandler/EngagmentEventHandler.vue'

export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    // BContainer,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCol,
    BRow,
    FilterListModal,
    BFormDatepicker,
    EngagmentEventHandler,
    // flatPickr,
  },
  filters: {
    moment(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
  data() {
    return {
      selected: [],
      selectType: '',
      pageLength: 10,
      dir: false,
      // codeColumnSearch,
      columns: [
        {
          label: 'Data',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Data',
          },
        },
        {
          label: 'Tipo Interazione',
          field: 'typeInteractionFrom',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Tipo',
          },
        },
        {
          label: 'Descrizione',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca descrizione',
          },
        },
        {
          label: 'Motivo',
          field: 'typeInteraction',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Telefono',
          },
        },
        {
          label: 'Persona',
          field: 'person',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca persona',
          },
        },
        {
          label: 'Paziente',
          field: 'patient',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca Paziente',
          },
        },
        {
          label: 'Tipo Oper. Cassa',
          field: 'cashOperation',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca tipo oper.',
          },
        },
        {
          label: 'Dettaglio Oper. Cassa',
          field: 'detailsCashOperation',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca dett.',
          },
        },
        {
          label: 'Metodo di pagamento',
          field: 'methodPayment',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca met pagamento',
          },
        },
        {
          label: 'Importo',
          field: 'totlaPayment',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca importo',
          },
        },
        {
          label: 'Azioni',
          field: 'action',
          filterOptions: {
            enabled: false,
          },
        },
      ],
      columnsFiltered: [],
      rows: [],
      searchTerm: '',
      dateStart: null,
      dateEnd: null,
      provaData: moment('2020-07-07', 'YYYY-MM-DD'),
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filterType() {
      if (moment(this.dateStart).isSameOrBefore(moment(this.dateEnd))) {
        return this.rows.filter(r => moment(r.date).isBetween(moment(this.dateStart), moment(this.dateEnd)))
      }
      return this.rows
    },
  },
  watch: {},
  /* this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data }) */
  created() {
    this.columnsFiltered = this.columns

    // console.log('Sono su Created di ListRoles')
    this.rows = [
      {
        id: 1,
        date: '2021-07-20',
        typeInteractionFrom: 'Telefonata',
        description: 'Informazioni prezzi',
        typeInteraction: 'Informazioni',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'No Cassa',
        detailsCashOperation: '',
        methodPayment: '',
        totlaPayment: '',
      },
      {
        id: 2,
        date: '2021-07-15',
        typeInteractionFrom: 'Telefonata',
        description: 'Informazioni prezzi',
        typeInteraction: 'Informazioni',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'No Cassa',
        detailsCashOperation: '',
        methodPayment: '',
        totlaPayment: '',
      },
      {
        id: 3,
        date: '2021-07-12',
        typeInteractionFrom: 'In Sede',
        description: 'Appuntamento',
        typeInteraction: 'Appuntamento',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Versamento',
        methodPayment: 'Contanti',
        totlaPayment: '90',
      },
      {
        id: 4,
        date: '2021-07-18',
        typeInteractionFrom: 'Social',
        description: 'Prestazione',
        typeInteraction: 'Visita generale',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Versamento',
        methodPayment: 'Assegno',
        totlaPayment: '350',
      },
      {
        id: 5,
        date: '2021-07-25',
        typeInteractionFrom: 'Campagna Ads',
        description: 'Prestazione',
        typeInteraction: 'Pacchetto Vita',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Nota Rientro sospeso di cassa',
        methodPayment: 'Carta di Credito',
        totlaPayment: '50',
      },
      {
        id: 6,
        date: '2021-07-10',
        typeInteractionFrom: 'Campagna Ads',
        description: 'Prestazione',
        typeInteraction: 'Pacchetto Vita',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Nota Rientro sospeso di cassa',
        methodPayment: 'Carta di Credito',
        totlaPayment: '50',
      },
      {
        id: 7,
        date: '2021-07-17',
        typeInteractionFrom: 'Referal',
        description: 'Prestazione',
        typeInteraction: 'Pacchetto Vita',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Nota Rientro sospeso di cassa',
        methodPayment: 'Carta di Credito',
        totlaPayment: '50',
      },
      {
        id: 8,
        date: '2021-07-23',
        typeInteractionFrom: 'Sito Web',
        description: 'Prestazione',
        typeInteraction: 'Pacchetto Vita',
        person: 'Pippo',
        patient: 'Pluto',
        cashOperation: 'Entrata',
        detailsCashOperation: 'Nota Rientro sospeso di cassa',
        methodPayment: 'Carta di Credito',
        totlaPayment: '50',
      },

    ]
  },
  methods: {
    resetDate() {
      this.dateStart = ''
      this.dateEnd = ''
    },
    showDetails() {
      // console.log('customer', params)
      // this.$router.push({ name: 'customers-details' })
    },
    givePropToModal(title) {
      this.$refs.addCustomer.title = title
    },
    filterColumns(columns) {
      columns.push('action')
      // console.log(columns)
      this.columnsFiltered = this.columns.filter(col => columns.includes(col.field))
    },
    prepareSidebarToEdit(engagment) {
      // console.log('prepareSidebarToEdit', user)
      // console.log('prepareSidebarToEdit', title)
      // console.log('editCustomer', user)
      this.$refs['sidebar-engagment'].setFormToEdit(engagment, 'Modifica Interazione')
    },
  },
}
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
